@use '../assets' as a;

.bg {
	background-image: linear-gradient(
			rgba(255, 255, 255, 0.2),
			rgba(255, 255, 255, 0.2)
		),
		url('../../img/pexels-bryan-geraldo-586415.jpg');
	padding-bottom: 15rem;
	// padding-top: 6rem;
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: cover;

	.button {
		margin-bottom: 3rem;
	}
}

@media screen and (min-width: 430px) {
	.bg {
		background-image: linear-gradient(
				rgba(255, 255, 255, 0.2),
				rgba(255, 255, 255, 0.2)
			),
			url('../../img/pexels-bryan-geraldo-586415.jpg');
		background-size: cover;
	}
}

.bg-ten {
	background-image: linear-gradient(
			rgba(255, 255, 255, 0.2),
			rgba(255, 255, 255, 0.2)
		),
		url('../../img/pexels-bryan-geraldo-586415.jpg');
	padding-bottom: 15rem;
	// padding-top: 6rem;
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: cover;

	.button {
		margin-bottom: 3rem;
	}
}

@media screen and (min-width: 430px) {
	.bg-ten {
		background-image: linear-gradient(
				rgba(255, 255, 255, 0.2),
				rgba(255, 255, 255, 0.2)
			),
			url('../../img/pexels-bryan-geraldo-586415.jpg');
		background-size: cover;
		background-position: center;
	}
}

.bg-contact {
	background-image: linear-gradient(
			rgba(255, 255, 255, 0.2),
			rgba(255, 255, 255, 0.2)
		),
		url('../../img/pexels-bryan-geraldo-586415.jpg');
	padding-bottom: 15rem;
	// padding-top: 6rem;
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: cover;

	.button {
		margin-bottom: 3rem;
	}
}

@media screen and (min-width: 430px) {
	.bg-contact {
		background-image: linear-gradient(
				rgba(255, 255, 255, 0.2),
				rgba(255, 255, 255, 0.2)
			),
			url('../../img/pexels-bryan-geraldo-586415.jpg');
		background-size: cover;
		background-position: center;
	}
}
