@use '../assets' as a;

.home {
	//background-color: a.$primary;
	background: rgb(70,18,13);
background: linear-gradient(90deg, rgba(70,18,13,1) 0%, rgba(88,22,16,1) 47%, rgba(70,18,13,1) 100%);
}

.container {
	padding: 0 2rem;
}

.main {
	min-height: 100vh;
	padding: 4rem 0;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.footer {
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	padding: 2rem 0;
	border-top: 1px solid #eaeaea;
	justify-content: space-between;
	align-items: center;
}

.footer a {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
}

.title a {
	color: #0070f3;
	text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
	text-decoration: underline;
}

.title {
	margin: 0;
	line-height: 1.15;
	font-size: 4rem;
	padding-bottom: 3rem;
}

.title,
.description {
	text-align: center;
}

.title {
	font-size: 57px;
}

.description {
	margin: 3rem 0;
	line-height: 1.5;
	//font-size: 1.6rem;
}

.code {
	background: #fafafa;
	border-radius: 5px;
	padding: 0.75rem;
	font-size: 1.1rem;
	font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
		Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 800px;
}

.card {
	margin: 1rem;
	padding: 2rem;
	text-align: left;
	color: inherit;
	text-decoration: none;
	border-radius: 2px;
	transition: color 0.15s ease, border-color 0.15s ease;
}

@media screen and (min-width: 768px) {
	.card {
		max-width: 450px;
	}
}

.card:hover,
.card:focus,
.card:active {
	color: #0070f3;
	border-color: #0070f3;
}

.card h2 {
	margin: 0 0 1rem 0;
}

.card p {
	margin: 0;
}

.logo {
	height: 1em;
	margin-left: 0.5rem;
}

@media (max-width: 600px) {
	.grid {
		width: 100%;
		flex-direction: column;
	}
}

// @media (prefers-color-scheme: dark) {
// 	.card,
// 	.footer {
// 		border-color: #222;
// 	}
// 	.code {
// 		background: #111;
// 	}
// 	.logo img {
// 		filter: invert(1);
// 	}
// }

.flex1200 {
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: center;
}
