@use '../assets' as a;

body .btn-container {
	width: fit-content;
	display: flex;
	justify-content: center;
}

.btn {
	padding: 0.8rem 2rem;
	border-radius: 8px;
	outline: none;
	border: none;
	cursor: pointer;
	max-width: min-content;
}

.btn--primary {
	background-color: a.$secondary;
	background-color: a.$secondary;
	color: a.$off-black;
	padding: .8rem;
	border-radius: 3px;
}

.btn--outline {
	background-color: transparent;
	color: #fff;
	padding: 0.8rem 2rem;
	border: 1px solid a.$primary;
	transition: all 0.3s ease-out;
}

.btn--medium {
	padding: 0.8rem 2rem;
	font-size: 2rem;
}

.btn--large {
	padding: 1.2rem 2.6rem;
	font-size: 2rem;
}

.btn--primary:hover {
	background-color: a.$secondary-hover;
}