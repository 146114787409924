//colors
$off-black: #161615;
$primary: #581618;
$secondary: #DCD6D0;
$off-white: #f1f1f1;
$gray: #DCD6D0;

//hover states
$off-black-hover: #323232;
$secondary-hover: #c3bdb7;

.primary {
	color: $primary;
}

.off-black {
	color: $off-black;
}

.off-white {
	color: $off-white;
}

//original green: test this #0F2E24
